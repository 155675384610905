import { useStaticQuery, graphql } from "gatsby";
import { FluidObject } from "gatsby-image";

export const useHouseAdministrationImage = (): FluidObject => {
  const { file } = useStaticQuery(graphql`
    query ServicesHeaderImage {
      file(relativePath: { eq: "images/services/house-administration.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  return file.childImageSharp.fluid;
};
