import { useStaticQuery, graphql } from "gatsby";
import { FluidObject } from "gatsby-image";

export const useBuildingAdministrationImage = (): FluidObject => {
  const { file } = useStaticQuery(graphql`
    query BuildingAdministrationImage {
      file(
        relativePath: { eq: "images/services/building-administration.png" }
      ) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  return file.childImageSharp.fluid;
};
