import { Container } from "@material-ui/core";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { ContactFormSection } from "../../../Shared/ContactFormSection/ContactFormSection";
import { ContactUsDivider } from "../../../Shared/ContactUsDivider/ContactUsDivider";
import { SectionHeader } from "../../../Shared/SectionHeader/SectionHeader";
import { usebuildingsAdministrationImage } from "../../hooks/usebuildingsAdministrationImage";
import { ServiceListItem } from "../ServiceListItem/ServiceListItem";
import { useBuildingAdministrationImage } from "../../hooks/useBuildingAdministrationImage";

export interface BuildingsAdministrationProps {
  className?: string;
}

const BuildingsAdministrationInner = (props: BuildingsAdministrationProps) => {
  const image = useBuildingAdministrationImage();
  const { t } = useTranslation("services");
  const services = useMemo(
    () =>
      t("buildingsAdministration.header.subheader.items", {
        returnObjects: true
      }),
    []
  );
  return (
    <div className={props.className}>
      <SectionHeader
        title={t("buildingsAdministration.header.title")}
        image={image}
        className={props.className}
      />
      <Container>
        <div className="service">
          <h2>{t("buildingsAdministration.header.subheader.title")}</h2>
          <p>{t("buildingsAdministration.header.subheader.text")}</p>
        </div>
        <div className="services">
          {services.map(service => (
            <ServiceListItem key={service.title} {...service} />
          ))}
        </div>
      </Container>
      <ContactUsDivider text="MÁTE ZÁUJEM O SPRÁVU VAŠEJ NEHNUTEĽNOSTI? ZAVOLAJTE NÁM!" />
      <ContactFormSection />
    </div>
  );
};

export const BuildingsAdministration = styled(BuildingsAdministrationInner)``;
