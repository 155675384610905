import { useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";
import styled from "styled-components";
import { PageBreadcrumb } from "../../../Shared/PageBreadcrumb/PageBreadcrumb";

export interface ServicesHeaderProps {
  className?: string;
}

const ServicesHeaderInner = (props: ServicesHeaderProps) => {
  const { t } = useTranslation(["common", "services"]);
  return <PageBreadcrumb breadCrumbText={t("common:routes.services")} />;
};

export const ServicesHeader = styled(ServicesHeaderInner)``;
