import React from "react";
import { Link } from "gatsby";

import Layout from "../components/Shared/Layout/Layout";
import { ServicesHeader } from "../components/sluzby/components/ServicesHeader/ServicesHeader";
import { HouseAdministration } from "../components/sluzby/components/HouseAdministration/HouseAdministration";
import { BuildingsAdministration } from "../components/sluzby/components/BuildingsAdministration/BuildingsAdministration";
import { Box } from "@material-ui/core";
import { theme } from "../lib/Theme";

const SluzbyPage = () => (
  <Layout>
    <ServicesHeader />
    <HouseAdministration />
    <Box mt={theme.spacing.sectionMarginTop}>
      <BuildingsAdministration />
    </Box>
  </Layout>
);

export default SluzbyPage;
