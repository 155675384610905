import React from "react";
import styled from "styled-components";
import { Divider } from "../Divider/Divider";
import { Heading } from "../Heading/Heading";
import { Button } from "@material-ui/core";
import { routes } from "../../../lib/routes";
import { AltroButton } from "../../home/DocumentsLinkSection/DocumentsLinkSection";
import { useTranslation } from "react-i18next";
import { muiTheme } from "../../../lib/Theme";

export interface ContactUsDividerProps {
  className?: string;
  text?: string;
}

const PhoneNumber = styled.a`
  font-size: 3rem;
  color: ${props => props.theme.color.primary};
  font-weight: 600;
`;

const OpeningHours = styled.p`
  margin-top: 10px;
  text-align: center;
  font-size: 1.8rem;
`;

const ContactUsDividerInner = (props: ContactUsDividerProps) => {
  const { t } = useTranslation(["services", "common"]);
  const { text } = props;
  return (
    <Divider className={props.className}>
      <Heading>{text || t("divider.text")}</Heading>
      <div className="info">
        <PhoneNumber href={`tel:${t("common:contactInformation.phoneNumber")}`}>
          {t("common:contactInformation.phoneNumber")}
        </PhoneNumber>
        <OpeningHours>
          {t("common:contactInformation.openingHours")}
        </OpeningHours>
      </div>
    </Divider>
  );
};

export const ContactUsDivider = styled(ContactUsDividerInner)`
  ${muiTheme.breakpoints.down("sm")} {
    .info {
      margin-top: 20px;
    }
  }
`;
