import { Container } from "@material-ui/core";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { ContactFormSection } from "../../../Shared/ContactFormSection/ContactFormSection";
import { ContactUsDivider } from "../../../Shared/ContactUsDivider/ContactUsDivider";
import { SectionHeader } from "../../../Shared/SectionHeader/SectionHeader";
import { useHouseAdministrationImage } from "../../hooks/useHouseAdministrationImage";
import { ServiceListItem } from "../ServiceListItem/ServiceListItem";

export interface HouseAdministrationProps {
  className?: string;
}

const HouseAdministrationInner = (props: HouseAdministrationProps) => {
  const image = useHouseAdministrationImage();
  const { t } = useTranslation("services");
  const services = useMemo(
    () =>
      t("houseAdministration.header.subheader.items", { returnObjects: true }),
    []
  );

  return (
    <div className={props.className}>
      <SectionHeader
        title={t("houseAdministration.header.title")}
        image={image}
        className={props.className}
      />
      <Container>
        <div className="service">
          <h2>{t("houseAdministration.header.subheader.title")}</h2>
          <p>{t("houseAdministration.header.subheader.text")}</p>
        </div>
        <div className="services">
          {services.map(service => (
            <ServiceListItem key={service.title} {...service} />
          ))}
        </div>
      </Container>
      <ContactUsDivider />
      <ContactFormSection />
    </div>
  );
};

export const HouseAdministration = styled(HouseAdministrationInner)`
  .service {
    margin-bottom: 30px;
  }
`;
