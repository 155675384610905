import React from "react";
import styled from "styled-components";

export interface ServiceListItemProps {
  title?: string;
  text?: string;
  list?: string[];
  className?: string;
}

const ServiceListItemInner = (props: ServiceListItemProps) => {
  const { title, text, list } = props;
  return (
    <div className={props.className}>
      {title && <h3>{title}</h3>}
      {text && <p>{text}</p>}
      <ul>
        {list && list.map(listItem => <li key={listItem}>{listItem}</li>)}
      </ul>
    </div>
  );
};

export const ServiceListItem = styled(ServiceListItemInner)`
  margin-bottom: 30px;

  p {
    font-weight: 600;
    color: black;
  }

  ul {
    list-style: none;
    margin-left: 0;
    padding-left: 20px;
  }

  li {
    color: ${props => props.theme.color.gray};
    &:before {
      content: "-";
      margin-right: 10px;
    }
  }
`;
